import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/apk',
    name: 'apk',
    component: () => import(/* webpackChunkName: "apk" */ '../views/ApkView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/privacy',
    name: 'privacy',
    component: () => import('../views/PrivacyView.vue')
  },
  {
    path:'/aboutUs',
    name: 'aboutUs',
    component: () => import('../views/AboutUsView.vue')
  },
  {
    path:'/savehtml',
    name: 'savehtml',
    component: () => import('../views/SaveHtmlView.vue')
  },
  {
    path:'/userprotocol',
    name: 'userprotocol',
    component: () => import('../views/UserProtocolView.vue')
  },
  {
    path:'/success',
    name: 'success',
    component: () => import('../views/SuccessView.vue')
  },
  {
    path:'/error',
    name: 'error',
    component: () => import('../views/ErrorView.vue')
  },
  {
    path:'/feedback',
    name: 'feedback',
    component: () => import('../views/FeedbackView.vue')
  },
  {
    path:'/translate',
    name: 'translate',
    component: () => import('../views/TranslateView.vue')
  },
  {
    path:'/pdf',
    name: 'pdf',
    component: () => import('../views/PdfView.vue')
  },
  {
    path:'/docx',
    name: 'docx',
    component: () => import('../views/DocxView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
